import copy from "copy-to-clipboard";
import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import styled, { css } from "styled-components";
import { palette } from "styles/theme";

interface Props {
  /**
   * A UUID to use for this copy element - needs to be unique across all
   * shown Tooltips on the page. If only one tooltip is shown at once,
   * its value doesn't technically matter.
   */
  id: string;

  /**
   * The text to copy on selection
   */
  text: string;

  /**
   * If specified, you can copy a specific thing. If this is "goat"
   * for example, the "Copied!" message becomes "Copied goat!".
   */
  noun?: string;

  /**
   * If this input is provided along w the noun then the copy messages becomes "Copy *noun*" instead of "Copy"
   */
  shouldUseCopyMessage?: boolean;

  /**
   * If you'd like to control the visibility of the wrapper yourself
   * instead of just on a hover, use this prop. Without this passed, just
   * shows on hover, disappears on unhover.
   */
  isShown?: boolean;

  /**
   * If you need to be notified about when something has actually copied,
   * use this.
   */
  setHasCopied?: (value: boolean) => void;

  /**
   * If you need to pass more than just the target, pass other
   * children here to be appended after the target while still being
   * copyable.
   */
  children?: React.ReactElement;

  /**
   * This element is where the copy popup shows from.
   */
  target: React.ReactElement;
}

const SHARED_STYLE = css`
  background: ${palette.black};
  box-shadow: 0px 8px 18px rgba(31, 45, 61, 0.07);
`;

// In px, the width of the little arrow at the bottom of the callout
const ARROW_SIZE = 12;

const CopyMessage = styled.div`
  ${SHARED_STYLE};
  color: ${palette.white};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;

  &:before {
    content: "";
    position: absolute;
    ${SHARED_STYLE};
    width: ${ARROW_SIZE}px;
    height: ${ARROW_SIZE}px;
    display: block;
    transform-origin: center;
    transform: rotate(-45deg);
    bottom: ${ARROW_SIZE - 8}px;
    left: calc(50% - ${ARROW_SIZE / 2}px);
    z-index: -1;
  }
`;

/**
 * Creates a wrapper around some child elements that allows for copying
 * some arbitrary text to the clipboard. Make sure the `id` is unique per
 * page, but don't worry about uniquness across pages.
 */
const CopyWrapper = ({
  id,
  text,
  noun,
  shouldUseCopyMessage,
  children,
  target,
  isShown,
  setHasCopied,
}: Props) => {
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);
  const copiedMessage = `Copied${noun ? ` ${noun}` : ""}!`;
  const copyMessage = shouldUseCopyMessage && noun != null ? `Copy ${noun}` : "Copy";

  const onCopy = () => {
    copy(text);
    setHasCopiedToClipboard(true);
    setHasCopied?.(true);
  };

  return (
    <>
      <OverlayTrigger
        show={isShown}
        placement="top"
        delay={{ show: 0, hide: 0 }}
        overlay={
          <Tooltip id={`tooltip-copy-${id}`} placement="top">
            <CopyMessage>{hasCopiedToClipboard ? copiedMessage : copyMessage}</CopyMessage>
          </Tooltip>
        }
      >
        <CopyToClipboard text={text} onCopy={onCopy}>
          {target}
        </CopyToClipboard>
      </OverlayTrigger>
      {children && (
        <CopyToClipboard text={text} onCopy={onCopy}>
          {children}
        </CopyToClipboard>
      )}
    </>
  );
};

export default CopyWrapper;
