import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks, { FlexRow } from "components/docs/navigation/CrossGuideLinks";
import LINKS from "components/docs/navigation/links";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import { Stack } from "components/generic/Stacks";
import { graphql, Link, PageProps, useStaticQuery } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { palette } from "styles/theme";

interface Config {
  description: string;
  troubleshootingTips: string;
}
interface Query {
  allValidationProblem: {
    nodes: Array<{
      problemType: string;
      title: string;
      warningConfig: null | Config;
      errorConfig: null | Config;
    }>;
  };
}

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

const IssueName = styled(NavigationScrollTrackerWithAnchor)`
  &&& {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 2px;
    font-weight: 400;
  }
`;

/**
 * Pretitle looking code
 */
const ProblemType = styled.p`
  &&& {
    font-size: 10px;
    line-height: 20px;
  }
  color: ${palette.gray};
  font-family: var(--font-family-monospace);
  font-weight: 600;
`;

const Badge = styled.span`
  &&& {
    font-size: 13px;
    font-weight: 600;
  }
`;

// Sometimes we span multiple rows with a td so the "first" cell isn't actually first and we need to reset padding as a result
const BadgeCell = styled.td`
  &&&& {
    padding: 16px 12px;
  }
`;

const Heading = styled.h5`
  color: ${palette.gray};
  &&& {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
  }
`;

const Paragraph = styled.p`
  word-wrap: break-word;
`;

const CrossLinksSection = styled(SmallWidthSection)`
  ${FlexRow} {
    border-top: none;
  }
`;

/**
 * Creates two cells for the badge + the config details
 */
const CellContents = ({ isWarning, config }: { isWarning?: boolean; config: Config }) => (
  <>
    <BadgeCell>
      <Badge className={isWarning ? "badge badge-soft-warning" : "badge badge-soft-danger"}>
        {isWarning ? "Warning" : "Error"}
      </Badge>
    </BadgeCell>
    <td>
      <Stack $isVertical>
        <Heading>Description</Heading>
        <Paragraph>{config.description}</Paragraph>
        <Heading>Troubleshooting Tips</Heading>
        <Paragraph>{config.troubleshootingTips}</Paragraph>
      </Stack>
    </td>
  </>
);

/**
 * Creates a guide for Troubleshooting Writes
 */
const TroubleshootingWrites = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const { allValidationProblem } = useStaticQuery<Query>(graphql`
    {
      allValidationProblem(sort: { fields: problemType }) {
        nodes {
          problemType
          title
          warningConfig {
            description
            troubleshootingTips
          }
          errorConfig {
            description
            troubleshootingTips
          }
        }
      }
    }
  `);
  return (
    <StaticFancyPageContainerWithTOC>
      <DocsHelmet
        title="Writes Warnings and Errors Reference"
        description="Learn more about the possible warnings and errors returned by Merge’s API’s POST endpoints and how to resolve them."
      />
      <SmallWidthSection>
        <NavigationScrollTracker>
          <HeaderBar
            title="Writes Warnings and Errors Reference"
            subtitle="Learn more about the possible warnings and errors returned by Merge’s API’s POST endpoints and how to resolve them"
          />
        </NavigationScrollTracker>
      </SmallWidthSection>

      <ShortSection>
        <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Warning and Error Codes">
          <p>
            This is a reference list of all possible errors and warnings returned by Merge’s API’s
            POST endpoints.
          </p>
          <aside className="mt-6">
            For more information on errors and warnings related to POST requests to Merge, check out
            the guide on{" "}
            <Link to={LINKS.MERGE_WRITES_TROUBLESHOOTING.linkTo}>Troubleshooting Writes</Link>.
          </aside>
        </NavigationScrollTrackerWithAnchor>
      </ShortSection>

      <Row>
        <Col lg={7} className="mt-9 mx-auto">
          <div className="d-flex flex-column align-items-center">
            <table>
              <thead>
                <tr>
                  <td>Issue</td>
                  <td>Type</td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {allValidationProblem.nodes.map(
                  ({ title, problemType, warningConfig, errorConfig }) => {
                    const hasError = !!errorConfig;
                    const hasWarning = !!warningConfig;
                    const hasBoth = hasError && hasWarning;
                    const errorCells = hasError && <CellContents config={errorConfig} />;
                    const warningCells = hasWarning && (
                      <CellContents isWarning config={warningConfig} />
                    );
                    return (
                      <React.Fragment key={title}>
                        <tr>
                          <td rowSpan={hasBoth ? 2 : 1}>
                            <IssueName headingLevel="h4" title={title} pageLoadOffsetInPx={-110}>
                              <ProblemType>{problemType}</ProblemType>
                            </IssueName>
                          </td>
                          {errorCells ?? warningCells}
                        </tr>
                        {hasBoth && <tr>{warningCells}</tr>}
                      </React.Fragment>
                    );
                  },
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>

      <CrossLinksSection className="mt-16 mb-9">
        <CrossGuideLinks location={location} usesQuaternaryLinks />
      </CrossLinksSection>
    </StaticFancyPageContainerWithTOC>
  );
};

export default TroubleshootingWrites;
