import React from "react";
import { Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { palette } from "styles/theme";

type Props = Pick<React.ComponentProps<"div">, "children" | "className"> & {
  /**
   * Fade content behind the section via an overlapping shadow
   */
  fadeBackground?: boolean;
};

// Turn off clicks on the wrapper so we can actually use the TOC
const Wrapper = styled(Row)`
  && {
    pointer-events: none;
  }
`;

// Has a background to be able to obscure a table of contents
export const Content = styled(Col)<{ $fadeBackground: boolean }>`
  pointer-events: auto;
  margin: auto;

  max-width: 664px;

  ${({ $fadeBackground }) =>
    $fadeBackground &&
    css`
      @media (min-width: 768px) {
        &&& {
          background: ${palette.white};
          box-shadow: 0 0 16px 16px ${palette.white};
        }
      }
    `}
`;

/**
 * Used in Guides for single column layouts that need to be
 * smaller in width.
 */
const SmallWidthSection = ({ fadeBackground = true, children, className }: Props) => (
  <Wrapper className={className}>
    <Content lg={7} $fadeBackground={fadeBackground}>
      {children}
    </Content>
  </Wrapper>
);

export default SmallWidthSection;
